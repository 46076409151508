const collaboratorReducer = (state, action) => {
    switch (action.type) {
        case 'GET_COLLABORATORS': {
            return {...state, collaborators: [...action.payload], error: {enabled: false, message: ""},
                values: action.values}
        }
        case 'GET_MAKER_TRIPS': {
            return {...state, collaborators: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_COLLABORATORS_FROM_PROVIDER': {
            return {...state, collaborators: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_COLLABORATORS_BY_ID': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_COLLABORATORS_AVAILABLE': {
            return {...state, collaborators_available: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_TOP_SKILLS_BY_ID': {
            return {...state, skills: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'SEND_COLLABORATOR_TP': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'DISCHARD_COLLABORATOR': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_CV_FILE': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_TALENT_POOL': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_CURRENT_PHASE': {
            const collaboratorUpdated = state?.collaborators.map(e => {
                if (e.nin === action.key) {
                    return action.payload;
                }
                return e;
            });
            return {...state, collaborators: collaboratorUpdated, error: {enabled: false, message: ""}}
        }
        case 'ADD_DETAIL_ITEMS_TP': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'ADD_REPLY_DETAIL_ITEM_TP': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_COLLABORATOR': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_COLLABORATORS': {
            return {...state, collaborators: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'PUT_COLLABORATORS': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_COLLABORATORS_BY_ID': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_COLLABORATORS_SALARY_BY_ID': {
            return {...state, collaborator: {...action.payload}, salaries: [...action.payload?.salaries], error: {enabled: false, message: ""}}
        }
        case 'PUT_MEETING_DATE': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'DISABLE_COLLABORATOR_NOTIFICATION_BY_ID': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_TALENT_POOL_COLLABORATOR_BY_ID': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }        
        case 'GET_SALARIES_COLLABORATOR_BY_ID': {
            return {...state, salaries: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_NOTES_COLLABORATOR_BY_ID': {
            return {...state, notes: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_ROLE_COLLABORATOR_BY_ID': {
            return {...state, role: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_COURSES_COLLABORATOR_BY_ID': {
            return {...state, courses: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'ADD_COLLABORATOR_NOTE': {
            return {...state, notes: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'COLLABORATORS_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        case 'UPDATE_CV_LINK': {
            return {...state, collaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_SUBSIDIARY_BY_BUK_NAME': {
            return {...state, subsidiary: {...action.payload}, error: {enabled: false, message: ""}}
        }         
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default collaboratorReducer;
