import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {ProjectContext} from './context';

const UseProject = () => {
    const context = useContext(ProjectContext);
    if (context === undefined) {
        throw new Error('UseCollaborator must be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const ProjectState = ({children}) => {
    const initialState = {
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
        projects_by_collaborator: {},
        project_collaborators: [],
        projects:[],
        values: false,
        profiles_collaborators: [],
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <ProjectContext.Provider value={value}>
            {children}
        </ProjectContext.Provider>
    );
};

export {UseProject, ProjectState};
